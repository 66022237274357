.card-code[data-v-5cff0742] {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.card-code pre[class*=language-][data-v-5cff0742] {
  max-height: 350px;
}
[dir] .card-code pre[class*=language-][data-v-5cff0742] {
  margin: 0;
  border-radius: 0.5rem;
}
.card-code[data-v-5cff0742] ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
[dir] .card-code[data-v-5cff0742] ::-webkit-scrollbar {
  background: #2d2d2d;
  border-radius: 100%;
}
[dir] .dark-layout .card-code[data-v-5cff0742] ::-webkit-scrollbar {
  background-color: #161d31 !important;
}
[dir] .card-code[data-v-5cff0742] ::-webkit-scrollbar-track {
  background: transparent;
}
[dir] .card-code[data-v-5cff0742] ::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: rgba(241, 241, 241, 0.4);
}
.card-code[data-v-5cff0742] ::-webkit-scrollbar-corner {
  display: none;
}
[dir] .code-toggler[data-v-5cff0742] {
  border-bottom: 1px solid transparent;
}
[dir] .code-toggler[aria-expanded=false][data-v-5cff0742] {
  border-bottom-color: #0875d6;
}
.card .card-header .heading-elements[data-v-5cff0742] {
  position: static;
}
[dir] .card .card-header .heading-elements[data-v-5cff0742] {
  background: red;
}